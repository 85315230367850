<template>
  <v-container>
    <v-card width="80%" :loading="isUpdating">
      <template v-slot:progress>
        <v-progress-linear
          absolute
          height="4"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="friends"
                :disabled="isUpdating"
                :items="cities"
                append-icon="mdi-magnify"
                chips
                color="orange lighten-2"
                item-text="name"
                item-value="name"
                multiple
                clearable
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    <v-avatar left>
                      <v-img :src="data.item.avatar"></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof(data.item) !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img :src="data.item.avatar" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.group"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-switch
          v-model="autoUpdate"
          :disabled="isUpdating"
          class="mt-0"
          color="green lighten-2"
          hide-details
          label="Auto Update"
        ></v-switch>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="autoUpdate"
          :loading="isUpdating"
          color="blue-grey darken-3"
          depressed
          @click="isUpdating = true"
        >
          <v-icon left> mdi-update </v-icon>
          Update Now
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-container class="px-0" style="overflow-y: scroll; height: 75vh">
      <h6 class="my-4" v-if="cities">Сонголтууд ({{ cities.length }})</h6>
      <v-row class="justify-start" v-if="cities">
        <TripCard2
          class="mx-4 mb-4"
          v-for="aimag in cities"
          :key="aimag.id"
          :item="aimag"
        ></TripCard2>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { db } from "@/firebaseConfig.js";
import TripCard2 from "./TripCard2.vue";
export default {
  components: {
    TripCard2,
  },
  data() {
    const srcs = {
      1: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      2: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      3: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      4: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
      5: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
    };
    return {
      cities: null,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      autoUpdate: true,
      friends: ["Sandra Adams", "Britta Holt"],
      isUpdating: false,
      name: "Midnight Crssew",
      people: [],
      title: "The summer breeze",
    };
  },
  created() {
    db.collection("countries/Mongolia/cities")
      .orderBy("index", "asc")
      .onSnapshot((querySnapshot) => {
        this.cities = [];
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " x=> ", doc.data());
          let city = doc.data();
          city.id = doc.id;
          city.ref = doc.ref;

          this.cities.push(city);
        });
      });
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
  },
  methods: {
    remove(item) {
      const index = this.friends.indexOf(item.name);
      if (index >= 0) this.friends.splice(index, 1);
    },
    _goDetail() {
      console.log("xx");
      //this.$router.push({ name: "MyTrips", params: { tripId: 11 } });
      this.dialog = true;
    },
  },
};
</script>
<style>
.v-toolbar-title {
  font-size: 24px;
  line-height: 29px;
}
</style>